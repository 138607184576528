// export function authHeader() {
    
//     let user = JSON.parse(localStorage.getItem('user'));

//     if (user && user.token) {
//         return { 'Authorization': 'Bearer ' + user.token };
//     } else {
//         return {};
//     }
// }

export default function authHeader() {
    // let usr = {
    //   "id": "2",
    //   "name": "admin",
    //   "is_admin": 1,
    //   "jabatan_tim": null,
    //   "no_hp": null,
    //   "email": "admin@admin.com",
    //   "status": 1,
    //   "email_verified_at": "2021-08-18T04:46:19.000000Z",
    //   "current_team_id": null,
    // }
    // let user = JSON.parse(localStorage.getItem(usr));
    let token = JSON.parse(localStorage.getItem('token'));
  
    if (token) {
      return { Authorization: 'Bearer ' + token }; // for Spring Boot back-end
      // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
    } else {
      return {};
    }
}