import { createStore } from 'vuex';

import layout from './modules/layout';
import menu from './modules/menu'
import chartData from "./modules/chart"
import table from './modules/table';
import icon from "./modules/icon"
import masonry from "./modules/masonry"
import { authentication } from './modules/authentication';
import { auth } from './modules/auth';
import { users } from './modules/users';
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    authentication,
    auth,
    layout,
    menu,
    chartData,
    table,
    icon,
    users,
    masonry,
  }
})
