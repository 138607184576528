<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">Upload</li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end"></div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <form class="row g-3 needs-validation" novalidate="">
          <div class="col-12">
            <b>Upload File</b>
          </div>
          <div class="col-6 position-relative">
            <label class="form-label" for="validationTooltip03"
              >Month Periode</label
            >
            <select
              type="text"
              class="form-control"
              id="role"
              v-model="month_periode"
              @change="validationForm"
              v-bind:class="
                formSubmitted
                  ? month_periodeError
                    ? 'is-invalid'
                    : 'is-valid'
                  : ''
              "
            >
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">Agust</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
            <small
              class="align-end text-danger"
              v-if="month_periodeError && formSubmitted"
            >
              {{ month_periodeError }}
            </small>
          </div>
          <div class="col-6 position-relative">
            <label class="form-label" for="validationTooltip03"
              >Year Periode</label
            >
            <select
              type="text"
              class="form-control"
              id="role"
              v-model="year_periode"
              @change="validationForm"
              v-bind:class="
                formSubmitted
                  ? year_periodeError
                    ? 'is-invalid'
                    : 'is-valid'
                  : ''
              "
            >
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2027">2027</option>
            </select>
            <small
              class="align-end text-danger"
              v-if="year_periodeError && formSubmitted"
            >
              {{ year_periodeError }}
            </small>
          </div>
        </form>
      </div>
    </div>

    <div class="row px-2 mb-1 mt-0">
      <div class="col-sm-6 p-1">
        <div class="card h-100">
          <div class="card-body border">
            <h5>File Excel (xlsx)</h5>
            <br />
            <!-- <DropZone
                :maxFileSize="Number(60000000)"
                :uploadOnDrop="true"
                :dropzoneMessageClassName="customClass"> 
              </DropZone> -->
            <input
              class="form-control bg-light py-5 px-4"
              id="file1"
              type="file"
              ref="fileupload"
              @change="fileShow"
              accept="xlxs/*"
              v-bind:class="
                formSubmitted ? (!file ? 'is-invalid' : 'is-valid') : ''
              "
            />
            <small class="text-danger" v-if="!file && formSubmitted">
              File is required !
            </small>
          </div>
          <div>
            <!-- <img :src="previewName2" class="rounded mr-4 mt-4 w-20" width="100" /> -->
            <!-- <span v-if="file"> {{previewName}}</span> -->
          </div>
        </div>
      </div>
      <div class="col-sm-6 p-1">
        <div class="card h-100">
          <div class="card-body border">
            <h5>File Text (txt)</h5>
            <br />
            <!-- <DropZone
                :maxFileSize="Number(60000000)"
                :uploadOnDrop="true"
                :dropzoneMessageClassName="customClass"> 
              </DropZone> -->
            <input
              class="form-control form-control-sm py-5 px-4 bg-light"
              id="file2"
              ref="fileupload2"
              type="file"
              @change="showtxt"
              accept="text/plain"
              v-bind:class="
                formSubmitted ? (!file2 ? 'is-invalid' : 'is-valid') : ''
              "
            />
            <small class="text-danger" v-if="!file2 && formSubmitted">
              File is required !
            </small>
            <div>
              <!-- <img :src="previewName2" class="rounded mr-4 mt-4 w-20" width="100" /> -->
              <!-- <span v-if="file2"> {{previewName2}}</span> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <b-button
              variant="primary"
              class="btn btn-add btn-sm mb-2 px-4 btn-block w-100"
              @click="actionUpload()"
              :disabled="loading_upload"
            >
              <div v-if="loading_upload">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
                <span> Loading..</span>
              </div>
              <div v-else>Upload</div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import ApiService from "@/_services/api.service";
import DropZone from "dropzone-vue";
import { Header, Item } from "vue3-easy-data-table";
import Swal from "sweetalert2";

// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  components: { DropZone },
  data() {
    return {
      month_periode: "",
      year_periode: "",
      month_periodeError: "",
      year_periodeError: "",
      search_result: "",
      formSubmitted: false,
      loading: false,
      loading_upload: false,
      isEdit: false,
      file: "",
      file2: "",
      previewName: null,
      previewName2: null,
      fileError: "",
      file2Error: "",
      submitted: false,
    };
  },
  computed: {},

  methods: {
    uploadData() {
      const formData = new FormData();
      formData.append("month_periode", this.month_periode);
      formData.append("year_periode", this.year_periode);
      formData.append("fileexcel", this.file);
      formData.append("filetxt", this.file2);

      console.log("data", formData);
      ApiService.upload(formData)
        .then((response) => {
          console.log("berhasil", response.data);
          this.loading_upload = false;
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Upload success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.resetForm();
        })
        .catch((err) => {
          console.log(err);
          this.loading_upload = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Gagal",
            text: "Upload Failed",
            showConfirmButton: false,
            timer: 2500,
          });

          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
        });
    },

    actionUpload() {
      this.formSubmitted = true;
      this.validationForm();
      if (this.month_periode && this.year_periode && this.file && this.file2) {
        this.loading_upload = true;
        setTimeout(this.uploadData, 4000);
      }
    },

    validationForm() {
      console.log("validasiii");
      if (!this.month_periode) {
        this.month_periodeError = "Month periode is required !";

        console.log("eror month");
      } else {
        this.month_periodeError = "";
      }
      if (!this.year_periode) {
        this.year_periodeError = "Year periode is required !";
      } else {
        this.year_periodeError = "";
      }
      if (!this.file) {
        this.fileError = "File is required !";
      } else {
        this.year_periodeError = "";
      }
      if (!this.file2) {
        this.file2Error = "File is required !";
      } else {
        this.file2Error = "";
      }
    },

    fileShow(evt) {
      this.file = evt.target.files[0];
      // this.previewName = evt.target.files[0]['name'];
    },

    showtxt(evt) {
      console.log("ev", evt);
      this.file2 = evt.target.files[0];
      // this.previewName2 = evt.target.files[0]['name'];
    },

    resetForm() {
      console.log("rseset");
      this.month_periode = "";
      this.year_periode = "";
      this.file = "";
      this.file2 = "";
      this.search_result = "";
      this.formSubmitted = false;
      this.$refs.fileupload.value = null;
      this.$refs.fileupload2.value = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";

.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #d1d1d3 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.8px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
