<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Upload Sre
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end"></div>
    </div>

    <div class="row pb-0 mb-0">
      <div class="col-sm-6">
        <div class="card col-12 mb-2">
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-12">
                <b>Upload File Sre</b>
              </div>
            </form>
          </div>
        </div>
        <div class="card col-12 mb-2">
          <div class="card-body border">
            <h5>File Excel (xlsx)</h5>
            <br />
            <input
              class="form-control bg-light py-5 px-4"
              id="file1"
              ref="fileupload"
              type="file"
              name="files"
              @change="fileShow"
              accept="xlxs/*"
              v-bind:class="
                formSubmitted ? (!file ? 'is-invalid' : 'is-valid') : ''
              "
            />
            <small class="text-danger" v-if="!file && formSubmitted">
              File is required !
            </small>
          </div>
          <div>
            <!-- <img :src="previewName2" class="rounded mr-4 mt-4 w-20" width="100" /> -->
            <!-- <span v-if="file"> {{previewName}}</span> -->
          </div>
        </div>
        <div class="card col-sm-12">
          <div class="card-body">
            <b-button
              variant="primary"
              class="btn btn-add btn-sm mb-2 px-4 btn-block w-100"
              @click="actionUpload()"
              :disabled="loading_upload"
            >
              <div v-if="loading_upload">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="sr-only"></span>
                </div>
                <span> Loading..</span>
              </div>
              <div v-else>Upload</div>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import ApiService from "@/_services/api.service";
import DropZone from "dropzone-vue";
import { Header, Item } from "vue3-easy-data-table";
import Swal from "sweetalert2";

// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  components: { DropZone },
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      formSubmitted: false,
      loading: false,
      loading_upload: false,
      isEdit: false,
      file: "",
      previewName: null,
      fileError: "",
      params: "",
      submitted: false,
    };
  },
  computed: {},

  methods: {
    uploadData() {
      // this.search_result = true;
      const formData = new FormData();
      formData.append("fileexcel", this.file);

      console.log("data", formData);
      ApiService.uploadsre(formData)
        .then((response) => {
          console.log("berhasil", response.data);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Upload success",
            showConfirmButton: false,
            timer: 1500,
          });

          this.loading_upload = false;
          // this.search_result = false;
          this.resetForm();
        })
        .catch((err) => {
          console.log(err);
          this.loading_upload = false;
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Gagal",
            text: "Upload Failed",
            showConfirmButton: false,
            timer: 2500,
          });
          // this.search_result = false;

          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
        });
    },

    actionUpload() {
      this.formSubmitted = true;
      this.validationForm();
      if (this.file) {
        this.loading_upload = true;
        setTimeout(this.uploadData, 4000);
      }
    },

    validationForm() {
      console.log("validasiii");
      if (!this.file) {
        this.fileError = "File is required !";
      } else {
        this.year_periodeError = "";
      }
    },

    fileShow(evt) {
      this.file = evt.target.files[0];
      // this.previewName = evt.target.files[0]['name'];
    },

    resetForm() {
      console.log("rseset");
      this.month_periode = "";
      this.year_periode = "";
      this.file = "";
      this.formSubmitted = false;
      this.$refs.fileupload.value = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
@import "dropzone-vue/dist/dropzone-vue.common.css";

.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #d1d1d3 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.8px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
