import { createRouter, createWebHistory } from 'vue-router'
import BodyView from '../layout/BodyView.vue'
import auth from "../auth/auth.vue"
// import callback from '../components/callback';
import IndexDefault from '../components/dashboard/default/IndexDefault.vue'
import errorPage1 from '../errors/errorPage4.vue';
// import vue_leaflet from "../components/maps/vue_leaflet"
import login from '../auth/login';
import search_page from "../components/search/index"
import user_page from "../components/users/index"
import generate from "../components/generate/index"
import upload from "../components/upload/index"
import upload_sre from "../components/upload-sre/index"

// import pesan_page from "../components/pesan/indexPesan"
const SecretKey = process.env.VUE_APP_SECRET_KEY;
const CryptoJS = require("crypto-js");

const lvl = localStorage.getItem('lvl');
if (lvl) {
  const localToken = CryptoJS.AES.decrypt(
    localStorage.getItem("lvl").toString(),
    SecretKey
  ).toString(CryptoJS.enc.Utf8);

  var roles = localToken;
} else {
  var roles = 1;
}

if (roles == 1) {
  var routes = [
    {
      path: '/',
      component: BodyView,

      children: [
        {
          path: '',
          name: 'defaultRoot',
          component: IndexDefault,

        },

      ]
    },
    {
      path: '/dashboard',
      component: BodyView,
      children: [
        {
          path: 'default',
          name: 'defaultIndex',
          component: IndexDefault,
        },

      ]
    },

    {
      path: '/error-page1',
      name: 'errorPage1',
      component: errorPage1,
    },
    {
      path: "/pages",
      component: BodyView,
      children: [
        {
          path: "user",
          name: "user",
          component: user_page,
        },
        {
          path: "search-customer",
          name: "search",
          component: search_page
        },
        {
          path: "generate",
          name: "generate",
          component: generate
        },
        {
          path: "upload",
          name: "upload",
          component: upload
        },
        {
          path: "upload-sre",
          name: "upload-sre",
          component: upload_sre
        },

      ]
    },
    {
      path: "/auth",
      component: auth,
      children: [
        {
          path: "login",
          name: "login",
          component: login
        }
      ]
    },
  ]
}

else if (roles == 2) {
  var routes = [
    {
      path: '/',
      component: BodyView,

      children: [
        {
          path: '',
          name: 'defaultRoot',
          component: IndexDefault,

        },

      ]
    },
    {
      path: '/dashboard',
      component: BodyView,
      children: [
        {
          path: 'default',
          name: 'defaultIndex',
          component: IndexDefault,
        },

      ]
    },

    {
      path: '/error-page1',
      name: 'errorPage1',
      component: errorPage1,
    },
    {
      path: "/pages",
      component: BodyView,
      children: [
        {
          path: "search-customer",
          name: "search",
          component: search_page
        },
        {
          path: "generate",
          name: "generate",
          component: generate
        },
      ]
    },

    {
      path: "/auth",
      component: auth,
      children: [
        {
          path: "login",
          name: "login",
          component: login
        }
      ]
    },
  ]
}

else if (roles == 3) {
  var routes = [
    {
      path: '/',
      component: BodyView,

      children: [
        {
          path: '',
          name: 'defaultRoot',
          component: IndexDefault,

        },

      ]
    },
    {
      path: '/dashboard',
      component: BodyView,
      children: [
        {
          path: 'default',
          name: 'defaultIndex',
          component: IndexDefault,
        },

      ]
    },

    {
      path: '/error-page1',
      name: 'errorPage1',
      component: errorPage1,
    },
    {
      path: "/pages",
      component: BodyView,
      children: [
        {
          path: "upload",
          name: "upload",
          component: upload
        },
      ]
    },
    {
      path: "/auth",
      component: auth,
      children: [
        {
          path: "login",
          name: "login",
          component: login
        }
      ]
    },
  ]
}

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/auth/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const { authorize } = to.meta;

  if (authRequired && !loggedIn) {
    return next('auth/login');
  }
  next();
})


export default router
