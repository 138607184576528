<template>
  <div v-if="role">
    <div v-if="role == 1">
      <welcomeView />
    </div>
    <div v-if="role == 2">
      <welcomeResearch />
    </div>
    <div v-if="role == 3">
      <welcomeOpr />
    </div>
  </div>
</template>
<script>
import welcomeView from "../default/welcomeView.vue";
import welcomeResearch from "../default/welcomeResearch.vue";
import welcomeOpr from "../default/welcomeOpr.vue";
const SecretKey = process.env.VUE_APP_SECRET_KEY;
const CryptoJS = require("crypto-js");

export default {
  components: {
    welcomeView,
    welcomeResearch,
    welcomeOpr,
  },
  data() {
    return {
      role: 1,
    };
  },
  mounted() {
    const lvl = localStorage.getItem("lvl");
    if (lvl) {
      const localToken = CryptoJS.AES.decrypt(
        localStorage.getItem("lvl").toString(),
        SecretKey
      ).toString(CryptoJS.enc.Utf8);
      this.role = localToken;
    }
  },
};
</script>
