import axios from 'axios';
import authHeader from '../_helpers/auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class ApiService {
  listUser() {
    return axios.get(API_URL + 'user/list', { headers: authHeader() });
  }

  deleteUsers(id) {
    return axios.delete(API_URL + 'user/delete/' + id, { headers: authHeader() });
  }

  register(data) {
    return axios.post(API_URL + 'user/regist', data, { headers: authHeader() });
  }

  upload(data) {
    return axios.post(API_URL + 'file/upload', data, { headers: authHeader(true) });
  }

  uploadsre(data) {
    return axios.post(API_URL + 'file/sre', data, { headers: authHeader(true) });
  }

  search(data) {
    return axios.post(API_URL + 'customer/search', data, { headers: authHeader() });
  }

  generate(data) {
    // return axios.post(API_URL + 'generate/file', data, { headers: authHeader() });
    return axios.post(API_URL + 'generate/file', data, { responseType: 'blob', headers: authHeader() });
  }

}

export default new ApiService();