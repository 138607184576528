<template>
  <div class="container-fluid p-0 bgimage">
    <div class="row rounded">
      <div class="col-12">
        <div class="login-card">
          <!-- <h4 class="text-center">ok</h4> -->
          <form class="theme-form login-form rounded p-5">
            <h4>Login</h4>
            <h6>Dashboard Safe Keeping</h6>
            <div
              class="alert alert-danger danger alert-dismissible fade show"
              role="alert"
              v-if="message && submitted"
            >
              Incorrect username or password !!
              <button
                class="btn-close"
                type="button"
                @click="closeAlert"
              ></button>
            </div>
            <div class="form-group">
              <label>Username</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-user"></i></span>
                <input
                  v-model="username"
                  class="form-control border"
                  type="username"
                  required=""
                  placeholder="username"
                  :class="{ 'is-invalid': submitted && !username }"
                />
                <div
                  v-show="submitted && !username"
                  class="invalid-feedback bg-danger py-1 px-3"
                >
                  Username harus di isi !
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Password</label>
              <div class="input-group">
                <span class="input-group-text"><i class="icon-lock"></i></span>
                <input
                  v-model="password"
                  autocomplete=""
                  class="form-control"
                  :type="type"
                  name="login[password]"
                  required=""
                  placeholder="*********"
                  :class="{ 'is-invalid': submitted && !password }"
                />
                <div
                  v-show="submitted && !password"
                  class="invalid-feedback bg-danger py-1 px-3"
                >
                  Password Harus di isi !
                </div>
                <div class="show-hide">
                  <span class="m-l-5" @click="showPassword">
                    <i class="fa fa-eye"></i
                  ></span>
                </div>
              </div>
            </div>

            <div class="btn-block">
              <button
                class="btn btn-primary btn-block"
                type="button"
                @click="handleSubmit"
              >
                <span v-if="!loading">Login</span>
                <span v-if="loading">
                  <b-spinner small label="Small Spinner"></b-spinner>
                  Loading..</span
                >
              </button>
            </div>
            <div class="text-secondary text-center mt-5">
              <small>@2024 Safe Keeping</small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "@/router";
export default {
  name: "login",
  data() {
    return {
      message: "",
      loading: false,
      type: "password",
      username: "",
      password: "",
      submitted: false,
      number: "",
      min: 1000,
      max: 9999,
    };
  },

  computed: {
    loggedIn() {
      //   let user = localStorage.getItem('user');
      //   if(user){
      //   router.push('/');
      //   }
      // return this.$store.state.auth.status.loggedIn;
    },
  },

  created() {
    let user = localStorage.getItem("user");
    let token = localStorage.getItem("token");
    if (user && token) {
      router.push("/");
    }
    this.getRandomNumber();
    // this.$store.dispatch('authentication/logout');
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
      const user = {
        username: this.username,
        password: this.password,
      };
      if (this.username && this.password) {
        this.loading = true;
        this.$store.dispatch("auth/login", user).then(
          () => {
            router.push("/");
            console.log("success");
            this.loading = false;
          },
          (error) => {
            this.loading = false;
            this.message = error.response.data;
          }
        );
      }
    },

    getRandomNumber() {
      this.number = this.generateNumber();
    },
    generateNumber() {
      return Math.floor(Math.random() * (this.max - this.min + 1) + this.min);
    },

    closeAlert() {
      this.message = "";
    },

    showPassword: function () {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
  },
};
</script>

<style scoped>
.bgimage {
  background-color: #fff;
  background-image: url("../assets/images/bgg.jpeg");
  background-size: 100%;
}
</style>
