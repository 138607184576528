<template>
  <div class="container-fluid dashboard-default-sec">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">Hi, Opr !!</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
