<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Data User Admin
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end">
        <b-button
          variant="primary"
          class="btn btn-add btn-sm mb-2 px-4"
          @click="actionShow(true)"
        >
          Add User</b-button
        >
      </div>
    </div>
    <div class="row" v-if="add_user_show">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-3 position-relative">
                <label class="form-label" for="validationTooltip01"
                  >Fullname</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? fullnameError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="name"
                  placeholder="Fullname"
                  v-model="form.fullname"
                />
                <small
                  class="align-end text-danger"
                  v-if="fullnameError && formSubmitted"
                >
                  {{ fullnameError }}
                </small>
              </div>
              <div class="col-3 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Username</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? usernameError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="username"
                  placeholder="Username"
                  v-model="form.username"
                />
                <small
                  class="align-end text-danger"
                  v-if="usernameError && formSubmitted"
                >
                  {{ usernameError }}
                </small>
              </div>
              <div class="col-3 position-relative">
                <label class="form-label" for="validationTooltip03"
                  >Password</label
                >
                <input
                  type="password"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? passwordError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="password"
                  placeholder="Password"
                  v-model="form.password"
                />
                <small
                  class="align-end text-danger"
                  v-if="passwordError && formSubmitted"
                >
                  {{ passwordError }}
                </small>
              </div>
              <div class="col-3 position-relative">
                <label class="form-label" for="validationTooltip01">Role</label>
                <select
                  type="text"
                  class="form-control"
                  id="role"
                  v-model="form.role"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted ? (roleError ? 'is-invalid' : 'is-valid') : ''
                  "
                >
                  <option value="">Select Role</option>
                  <option value="1">Admin</option>
                  <option value="2">Research</option>
                  <option value="3">Opr</option>
                </select>
                <small
                  class="align-end text-danger"
                  v-if="roleError && formSubmitted"
                >
                  {{ roleError }}
                </small>
              </div>
              <div class="col-12 text-end">
                <b-button
                  variant="danger"
                  class="btn btn-add btn-sm mb-2 px-4"
                  @click="actionShow(false)"
                >
                  Cancel</b-button
                >
                &nbsp;
                <b-button
                  variant="success"
                  class="btn btn-add btn-sm mb-2 px-4"
                  @click="addUser"
                >
                  <span v-if="!loading_add">Save</span>
                  <span v-else> Loading ..</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body" v-if="!loading">
            <div class="row">
              <div class="col-sm-6 col-md-9 mt-2">
                <div class="d-flex align-items-center mr-2">
                  Filter Role : &nbsp;
                  <select
                    class="form-control"
                    style="width: 200px"
                    @click="filterRole"
                    v-model="filter_role"
                  >
                    <option value="">All</option>
                    <option value="1">Admin</option>
                    <option value="2">Research</option>
                    <option value="3">Opr</option>
                  </select>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-3 mt-2">
                <div class="d-flex"></div>
              </div>
              <!-- End search -->
            </div>

            <div class="table-responsive mt-2" v-if="tableData.length > 0">
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th>Full Name</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th class="text-center" width="8%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(list, idx) in tableData" :key="idx">
                    <td class="text-center">
                      {{ idx + 1 }}
                    </td>
                    <td>
                      {{ list.fullname }}
                    </td>
                    <td>{{ list.username }}</td>
                    <td>
                      <span
                        v-if="list.id_role_user == '1'"
                        class="badge-success badge"
                      >
                        Admin
                      </span>
                      <span
                        v-if="list.id_role_user == '2'"
                        class="badge badge-secondary"
                      >
                        Research
                      </span>
                      <span
                        v-if="list.id_role_user == '3'"
                        class="badge badge-info"
                      >
                        Operator
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="mr-1">
                        <b-button
                          @click="deleteUser(list.id, list.username)"
                          title="Hapus User"
                          class="btn btn-sm px-2 py-1"
                          variant="danger"
                          ><i class="fa fa-trash"></i
                        ></b-button>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="table-responsive mt-2" v-else>
              <table class="table table-striped table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th class="text-center" width="3%">No</th>
                    <th>Avatar</th>
                    <th>Full Name</th>
                    <th>Username</th>
                    <th>Role</th>
                    <th class="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="7" class="text-center">Data tidak ada!</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <div class="">
                <span>
                  Total Data :
                  <span v-if="tableData[0]">{{ tableData.length }}</span>
                  <span v-else>-</span>
                </span>
              </div>
            </div>
          </div>

          <div class="card-body" v-else>
            <div class="row">
              <div class="col-12 py-5 my-5">
                <div v-if="loading" class="mt-5 mx-auto p-5">
                  <div class="text-center">
                    <div
                      class="spinner-border text-secondary"
                      otp_ocr="email"
                      v-if="loading"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import ApiService from "@/_services/api.service";
import { Header, Item } from "vue3-easy-data-table";
import Swal from "sweetalert2";

// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  // components: { Vue3EasyDataTable },
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      add_user_show: false,
      filter_role: "",
      formSubmitted: false,
      loading: false,
      loading_add: false,
      isEdit: false,
      tableData: [],
      pagination: {
        links: [],
        curr_page: 0,
        last_page: 0,
        last_page: 0,
        per_page: 20,
        from: 0,
        to: 0,
        total: 0,
      },
      params: "",
      fullnameError: "",
      roleError: "",
      usernameError: "",
      passwordError: "",
      form: {
        id: "",
        fullname: "",
        username: "",
        password: "",
        role: "",
      },

      submitted: false,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    listUserAdmin() {
      this.loading = true;
      ApiService.listUser()
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data;
          console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id_user,
              fullname: val.fullname,
              username: val.username,
              id_role_user: val.id_role_user,
            };
            this.tableData.push(data);
          });
          this.loading = false;
        })
        .catch((err) => {
          // console.log('err', err.response.status);
          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
          this.loading = false;
        });
    },

    listUserAdminReload() {
      // this.loading = true;
      ApiService.listUser()
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data;
          console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id_user,
              fullname: val.fullname,
              username: val.username,
              id_role_user: val.id_role_user,
            };
            this.tableData.push(data);
          });
          this.loading = false;
        })
        .catch((err) => {
          // console.log("err", err.response.status);
          if (err.response.status == 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
          }
          this.loading = false;
        });
    },

    addUser() {
      this.formSubmitted = true;
      this.validationForm();

      if (
        this.form.fullname &&
        this.form.username &&
        this.form.password &&
        this.form.role
      ) {
        this.validation = true;
        this.loading_add = true;
        const data = {
          fullname: this.form.fullname,
          username: this.form.username,
          password: this.form.password,
          id_role_user: this.form.role,
        };
        ApiService.register(data)
          .then((response) => {
            console.log("berhasil", response.data);
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "add data success",
              showConfirmButton: false,
              timer: 1500,
            });
            this.loading_add = false;
            this.add_user_show = false;
            this.resetForm();
            this.listUserAdminReload();
          })
          .catch((err) => {
            console.log(err);
            this.loading_add = false;

            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "add data failed",
              showConfirmButton: false,
              timer: 1500,
            });

            if (err.response) {
              if (err.response.status == 401) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
              }
            }
          });
      }
    },

    deleteUser(id, name) {
      Swal.fire({
        title: "Are you sure, want to delete this user?",
        text: name,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#153F37",
        cancelButtonColor: "#eeeeee",
        cancelButtonText: "Cancel",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("id", id);
          ApiService.deleteUsers(id)
            .then((response) => {
              this.listUserAdminReload();
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Deleted success",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch((err) => {
              console.log("err ", err);
              Swal.fire({
                position: "top-end",
                icon: "error",
                title: "Gagal",
                text: "Deleted Failed",
                showConfirmButton: false,
                timer: 2500,
              });
            });

          //   if(err.response.status == 401){
          //   localStorage.removeItem("user");
          //   localStorage.removeItem("token");
          // }
        }
      });
    },

    actionShow(value) {
      this.add_user_show = value;
      if (!value) {
        this.resetForm();
      }
    },

    filterRole() {
      ApiService.listUser()
        .then((response) => {
          this.tableData = [];
          let data_tim = response.data.data;
          console.log("data", response.data.data);
          data_tim.forEach((val, index) => {
            const data = {
              no: index + 1,
              id: val.id_user,
              fullname: val.fullname,
              username: val.username,
              id_role_user: val.id_role_user,
            };

            if (this.filter_role == val.id_role_user) {
              this.tableData.push(data);
            } else if (this.filter_role == "") {
              this.tableData.push(data);
            }
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
          this.loading = false;
        });
    },

    validationForm() {
      if (!this.form.fullname) {
        this.fullnameError = "Name is required !";
      } else {
        this.fullnameError = "";
      }
      if (!this.form.username) {
        this.usernameError = "Username is required !";
      } else {
        this.usernameError = "";
      }
      if (!this.form.role) {
        this.roleError = "Role is required !";
      } else {
        this.roleError = "";
      }
      if (!this.form.password) {
        this.passwordError = "Password is required !";
      } else {
        this.passwordError = "";
      }
    },

    resetForm() {
      console.log("rseset");
      this.form.fullname = "";
      this.form.username = "";
      this.form.password = "";
      this.form.role = "";
      this.formSubmitted = false;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.listUserAdmin();
  },
};
</script>

<style lang="scss">
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #d1d1d3 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.8px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
