import axios from 'axios';
const API_URL = process.env.VUE_APP_API_URL;
const SecretKey = process.env.VUE_APP_SECRET_KEY;
const CryptoJS = require("crypto-js");

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'auth/login', {
        password: user.password,
        username: user.username
      })
      .then(response => {
        // console.log('berhasil', response);
        if (response.status == 200) {
          const user = JSON.stringify(response.data.data);
          const new_user = {
            'fullname': JSON.parse(user)['username'],
            'username': JSON.parse(user)['fullname']
          }
          const role_id = JSON.parse(user)['id_role_user'];
          localStorage.setItem('lvl', CryptoJS.AES.encrypt(role_id.toString(), SecretKey).toString());
          localStorage.setItem('user', JSON.stringify(new_user));
          localStorage.setItem('token', JSON.stringify(response.data.access_token));
          localStorage.setItem('name', JSON.parse(user)['username']);
          localStorage.setItem('full', JSON.parse(user)['fullname']);

          // const localToken = CryptoJS.AES.decrypt(localStorage.getItem('lvl').toString(), SecretKey).toString(CryptoJS.enc.Utf8)
          // console.log('end role', localToken);
        }
        return response.data;
        this.$router.go('/');
      });
  }

  logout() {
    localStorage.removeItem('user');
  }
}

export default new AuthService();