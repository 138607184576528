<template>
  <div id="mainnav">
    <ul
      class="nav-menu custom-scrollbar"
      id="myDIV"
      :style="[
        layoutobject.split(' ').includes('horizontal-wrapper')
          ? layout.settings.layout_type == 'rtl'
            ? { '  -right': margin + 'px' }
            : { 'margin-left': margin + 'px' }
          : { margin: '0px' },
      ]"
      v-if="role"
    >
      <li class="back-btn">
        <div class="mobile-back text-end">
          <span>Back</span>
          <i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li>
        <!-- <small class="text-danger">{{ role}}</small> -->
      </li>
      <li
        v-for="(menu, index) in menuItems"
        :key="index"
        :class="{
          'sidebar-main-title': menu.type == 'headtitle',
        }"
        class="dropdown d-block"
      >
        <div v-if="menu.type == 'headtitle' && menu.role == role">
          <b style="color: #010101">{{ menu.headTitle1 }}</b>
        </div>

        <!-- sub -->
        <a
          href="javascript:void(0)"
          :class="{ active: menu.active }"
          class="nav-link menu-title"
          v-if="menu.type == 'sub'"
          @click="setNavActive(menu, index)"
          ><vue-feather :type="menu.icon" class="home"></vue-feather>
          <span>
            {{ menu.title }}
          </span>
          <div class="according-menu" v-if="menu.children">
            <i
              class="pull-right"
              :class="[menu.active ? 'fa fa-angle-down' : 'fa fa-angle-right']"
            ></i></div
        ></a>
        <!-- Link -->
        <router-link
          :to="menu.path"
          class="nav-link menu-title"
          v-if="menu.type == 'link' && menu.role == role"
          router-link-exact-active
          exact
          v-on:click="hidesecondmenu()"
        >
          <vue-feather :type="menu.icon" class="home"></vue-feather>
          <span class="menu1">
            {{ menu.title }}
          </span>
          <vue-feather type="chevron-right" v-if="menu.children"></vue-feather>
        </router-link>
        <a
          :href="menu.path"
          class="sidebar-link sidebar-title"
          v-if="menu.type == 'extLink'"
          @click="setNavActive(menuItem, index)"
        >
          <feather :type="menu.icon" class="top"></feather>
          <span>
            {{ menu.title }}
          </span>
          <i class="fa fa-angle-right pull-right" v-if="menuItem.children"></i>
        </a>
        <!-- External Tab Link -->
        <a
          :href="menuItem.path"
          target="_blank"
          class="sidebar-link sidebar-title"
          v-if="menu.type == 'extTabLink'"
          @click="setNavActive(menu, index)"
        >
          <feather :type="menuItem.icon" class="top"></feather>
          <span>
            {{ menu.title }}
          </span>
          <i class="fa fa-angle-right pull-right" v-if="menu.children"></i>
        </a>
        <!-- 2nd level menu -->
        <ul
          class="nav-submenu menu-content"
          v-if="menu.children"
          :class="{ 'menu-open d-block': menu.active }"
        >
          <li v-for="(childrenItem, index) in menu.children" :key="index">
            <!-- Sub -->
            <a
              class="submenu-title"
              href="javascript:void(0)"
              v-if="childrenItem.type == 'sub'"
              @click="setNavActive(childrenItem, index)"
            >
              {{ childrenItem.title }}
              <label
                :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                v-if="childrenItem.badgeType"
                >{{ childrenItem.badgeValue }}</label
              >

              <i
                class="pull-right"
                :class="[
                  childrenItem.active
                    ? 'fa fa-angle-down'
                    : 'fa fa-angle-right',
                ]"
                v-if="childrenItem.children"
              ></i>
            </a>
            <!-- Link -->
            <router-link
              class="submenu-title"
              :to="childrenItem.path"
              v-if="childrenItem.type == 'link'"
              router-link-exact-active
              exact
              v-on:click="hidesecondmenu()"
            >
              {{ childrenItem.title }}
              <label
                :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                v-if="childrenItem.badgeType"
                >{{ childrenItem.badgeValue }}</label
              >
              <i
                class="fa fa-angle-right pull-right mt-1"
                v-if="childrenItem.children"
              ></i>
            </router-link>
            <!-- External Link -->
            <a
              :href="childrenItem.path"
              v-if="childrenItem.type == 'extLink'"
              class="submenu-title"
            >
              {{ childrenItem.title }}
              <label
                :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                v-if="childrenItem.badgeType"
                >{{ childrenItem.badgeValue }}</label
              >
              <i
                class="fa fa-angle-right pull-right mt-1"
                v-if="childrenItem.children"
              ></i>
            </a>
            <!-- External Tab Link -->
            <a
              class="submenu-title"
              :href="childrenItem.path"
              target="_blank"
              v-if="childrenItem.type == 'extTabLink'"
            >
              {{ childrenItem.title }}
              <label
                :class="'badge badge-' + childrenItem.badgeType + ' pull-right'"
                v-if="childrenItem.badgeType"
                >{{ childrenItem.badgeValue }}</label
              >
              <i
                class="fa fa-angle-right pull-right mt-1"
                v-if="childrenItem.children"
              ></i>
            </a>
            <!-- 3rd Level Menu -->
            <ul
              class="nav-sub-childmenu submenu-content"
              v-if="childrenItem.children"
              :class="{ opensubchild: childrenItem.active }"
            >
              <li
                v-for="(childrenSubItem, index) in childrenItem.children"
                :key="index"
              >
                <!-- Link -->
                <router-link
                  :to="childrenSubItem.path"
                  v-if="childrenSubItem.type == 'link'"
                  router-link-exact-active
                  exact
                  v-on:click="hidesecondmenu()"
                >
                  {{ childrenSubItem.title }}
                  <label
                    :class="
                      'badge badge-' + childrenSubItem.badgeType + ' pull-right'
                    "
                    v-if="childrenSubItem.badgeType"
                    >{{ childrenSubItem.badgeValue }}</label
                  >
                  <i
                    class="fa fa-angle-right pull-right"
                    v-if="childrenSubItem.children"
                  ></i>
                </router-link>
                <!-- External Link -->
                <router-link
                  :to="childrenSubItem.path"
                  v-if="childrenSubItem.type == 'extLink'"
                  router-link-exact-active
                >
                  {{ childrenSubItem.title }}
                  <label
                    :class="
                      'badge badge-' + childrenSubItem.badgeType + ' pull-right'
                    "
                    v-if="childrenSubItem.badgeType"
                    >{{ childrenSubItem.badgeValue }}</label
                  >
                  <i
                    class="fa fa-angle-right pull-right"
                    v-if="childrenSubItem.children"
                  ></i>
                </router-link>
                <!-- External Tab Link -->
                <router-link
                  :to="childrenSubItem.path"
                  v-if="childrenSubItem.type == 'extLink'"
                  router-link-exact-active
                >
                  {{ childrenSubItem.title }}
                  <label
                    :class="
                      'badge badge-' + childrenSubItem.badgeType + ' pull-right'
                    "
                    v-if="childrenSubItem.badgeType"
                    >{{ childrenSubItem.badgeValue }}</label
                  >
                  <i
                    class="fa fa-angle-right pull-right"
                    v-if="childrenSubItem.children"
                  ></i>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<script>
import VueFeather from "vue-feather";
import { mapState } from "vuex";
import { layoutClasses } from "../../constants/layout";
const SecretKey = process.env.VUE_APP_SECRET_KEY;
const CryptoJS = require("crypto-js");

export default {
  components: {
    VueFeather,
  },
  data() {
    return {
      layoutobj: {},
      role: 1,
    };
  },
  computed: {
    ...mapState({
      menuItems: (state) => state.menu.data,
      layout: (state) => state.layout.layout,
      sidebar: (state) => state.layout.sidebarType,
      activeoverlay: (state) => state.menu.activeoverlay,
      togglesidebar: (state) => state.menu.togglesidebar,
      width: (state) => state.menu.width,
      height: (state) => state.menu.height,
      margin: (state) => state.menu.margin,
      menuWidth: (state) => state.menu.menuWidth,
    }),
    sidebarType() {
      return this.$route.query.layout || "default";
    },
    layoutobject: {
      get: function () {
        return JSON.parse(
          JSON.stringify(
            layoutClasses.find(
              (item) => Object.keys(item).pop() === this.layout.settings.layout
            )
          )
        )[this.layout.settings.layout];
      },
      set: function () {
        this.layoutobj = layoutClasses.find(
          (item) => Object.keys(item).pop() === this.layout.settings.layout
        );
        this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[
          this.layout.settings.layout
        ];
        return this.layoutobj;
      },
    },
  },
  watch: {
    width() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
      window.addEventListener("scroll", this.handleScroll);
      this.handleScroll();
      if (window.innerWidth < 992) {
        const newlayout = JSON.parse(
          JSON.stringify(this.layoutobject).replace(
            "horizontal-wrapper",
            "compact-wrapper"
          )
        );
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + newlayout;
        this.$store.state.menu.margin = 0;
      } else {
        document.querySelector(".page-wrapper").className =
          "page-wrapper " + this.layoutobject;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    setTimeout(() => {
      const elmnt = document.getElementById("myDIV");
      this.$store.state.menu.menuWidth = elmnt.offsetWidth;
      this.$store.state.menu.menuWidth > window.innerWidth
        ? ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = false))
        : ((this.$store.state.menu.hideRightArrow = false),
          (this.$store.state.menu.hideLeftArrowRTL = true));
    }, 500);
    this.layoutobject = layoutClasses.find(
      (item) => Object.keys(item).pop() === this.layout.settings.layout
    );
    this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[
      this.layout.settings.layout
    ];
  },

  mounted() {
    const lvl = localStorage.getItem("lvl");
    if (lvl) {
      const localToken = CryptoJS.AES.decrypt(
        localStorage.getItem("lvl").toString(),
        SecretKey
      ).toString(CryptoJS.enc.Utf8);
      this.role = localToken;
    }

    this.menuItems.filter((items) => {
      if (items.path === this.$route.path)
        this.$store.dispatch("menu/setActiveRoute", items);
      if (!items.children) return false;
      items.children.filter((subItems) => {
        if (subItems.path === this.$route.path)
          this.$store.dispatch("menu/setActiveRoute", subItems);
        if (!subItems.children) return false;
        subItems.children.filter((subSubItems) => {
          if (subSubItems.path === this.$route.path)
            this.$store.dispatch("menu/setActiveRoute", subSubItems);
        });
      });
    });
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 400) {
        if (
          this.layoutobject.split(" ").pop() === "material-type" ||
          this.layoutobject.split(" ").pop() === "normal-sidebar"
        )
          document.querySelector(".sidebar-main").className =
            "sidebar-main hovered";
      } else {
        if (document.getElementById("sidebar-main"))
          document.querySelector(".sidebar-main").className = "sidebar-main";
      }
    },
    setNavActive(item) {
      this.$store.dispatch("menu/setNavActive", item);
      if (
        this.layoutobject.split(" ").includes("compact-sidebar") &&
        window.innerWidth > 991
      ) {
        if (this.menuItems.some((menuItem) => menuItem.active === true)) {
          this.$store.state.menu.activeoverlay = true;
        } else {
          this.$store.state.menu.activeoverlay = false;
        }
      }
    },
    hidesecondmenu() {
      if (window.innerWidth < 991) {
        (this.$store.state.menu.activeoverlay = false),
          (this.$store.state.menu.togglesidebar = false);
        this.menuItems.filter((menuItem) => {
          menuItem.active = false;
        });
      } else if (this.layoutobject.split(" ").includes("compact-sidebar")) {
        (this.$store.state.menu.activeoverlay = false),
          this.menuItems.filter((menuItem) => {
            menuItem.active = false;
          });
      }
    },
    handleResize() {
      this.$store.state.menu.width = window.innerWidth - 300;
    },
  },
};
</script>
