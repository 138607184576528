<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              Search Customer
            </li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end"></div>
    </div>

    <div class="row mb-0">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-12">
                <b>Search Customer</b>
              </div>
              <div class="col-5 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >No Customer</label
                >
                <input
                  type="text"
                  class="form-control"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? no_customerError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  id="no_customer"
                  placeholder="No customer"
                  v-model="no_customer"
                />
                <small
                  class="align-end text-danger"
                  v-if="no_customerError && formSubmitted"
                >
                  {{ no_customerError }}
                </small>
              </div>
              <div class="col-5 position-relative">
                <label class="form-label" for="validationTooltip03"
                  >Month</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="role"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? last_monthError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                  v-model="latest_month"
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">Agust</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <small
                  class="align-end text-danger"
                  v-if="latest_monthError && formSubmitted"
                >
                  {{ latest_monthError }}
                </small>
              </div>
              <div class="col-2 position-relative">
                <label class="form-label" for="validationTooltip01"
                  >&nbsp;</label
                >
                <br />
                <b-button
                  variant="primary"
                  class="btn btn-add btn-sm mb-2 px-4 btn-block w-100"
                  @click="actionSearch()"
                  :disable="loading_search"
                >
                  <div v-if="loading_search">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                    <span> Loading..</span>
                  </div>
                  <div v-else>Search</div>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-0 pt-0" v-if="search_action && !loading_search">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body" v-if="!loading_search">
            <div class="row">
              <div class="col-8">
                <b class="text-secondary mb-1">Data Customer</b>
                <div class="border py-2 mb-3 mt-1 px-3" v-if="search_result[0]">
                  <span
                    >Cust Name : <b> {{ search_result[0].name }}</b></span
                  >
                </div>
              </div>
              <div class="col-4 text-end">
                <b-button
                  variant="danger"
                  class="btn btn-add btn-sm px-3 mb-2"
                  @click="resetForm()"
                >
                  Reset</b-button
                >
              </div>

              <div class="col-12">
                <div class="py-4 border m-5">
                  <div class="table-responsive" v-if="search_result[0]">
                    <b class="px-3 py-3">Viewer Data</b>
                    <table
                      class="table table-bordered table-striped table-sm"
                      v-if="search_result[0]"
                    >
                      <thead>
                        <tr>
                          <th width="15%" class="bg-primary">Index</th>
                          <th
                            v-for="(l, idx) in search_result"
                            :key="idx"
                            class="bg-info"
                          >
                            {{ idx }}
                          </th>
                        </tr>
                        <tr
                          v-for="(list, field) in search_result[0]"
                          :key="field"
                        >
                          <th v-if="field == 'sre'">
                            <span v-if="field == 'sre'"><b>SRE</b></span>
                            <span v-else>{{ field }}</span>
                          </th>
                          <template
                            v-for="(ls, idx) in search_result"
                            :key="idx"
                          >
                            <td v-if="field == 'sre'">
                              <b>{{ ls["sre"] }}</b>
                            </td>
                          </template>
                        </tr>
                        <tr
                          v-for="(list, field) in search_result[0]"
                          :key="field"
                        >
                          <th v-if="field !== 'sre'">
                            <span v-if="field == 'sre'"><b>SRE</b></span>
                            <span v-else>{{ field }}</span>
                          </th>
                          <template
                            v-for="(ls, idx) in search_result"
                            :key="idx"
                          >
                            <td v-if="field !== 'sre'">
                              <span v-if="ls[field] !== null">{{
                                ls[field]
                              }}</span>
                              <span v-else>0</span>
                            </td>
                          </template>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="text-center p-5" v-else>
                    <span class="py-5 my-5" v-if="!loading_search">
                      Data Not Found !!
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body" v-else>
            <div class="row">
              <div class="col-12 py-5 my-5">
                <div v-if="loading_search" class="mt-5 mx-auto p-5">
                  <div class="text-center">
                    <div
                      class="spinner-border text-secondary"
                      v-if="loading_search"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import ApiService from "@/_services/api.service";
import { Header, Item } from "vue3-easy-data-table";
import Swal from "sweetalert2";

// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  // components: { Vue3EasyDataTable },
  data() {
    return {
      latest_month: "1",
      no_customer: "",
      latest_monthError: "",
      no_customerError: "",
      search_result: [],
      search_action: false,
      formSubmitted: false,
      loading: false,
      loading_search: false,
      isEdit: false,
      submitted: false,
    };
  },
  computed: {},

  methods: {
    searchData() {
      const data = {
        latest_month: this.latest_month,
        no_cust: this.no_customer,
      };
      this.search_result = [];
      console.log("data", data);
      ApiService.search(data)
        .then((response) => {
          console.log("berhasil", response.data);
          this.loading_search = false;
          this.search_result = response.data.data;
          // this.resetForm();
        })
        .catch((err) => {
          console.log(err);
          this.loading_search = false;
          // this.search_result = false;

          if (err.response.status == 401) {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
          }
        });
    },

    actionSearch() {
      this.formSubmitted = true;
      this.validationForm();
      if (this.latest_month && this.no_customer) {
        this.loading_search = true;
        this.search_action = true;
        setTimeout(this.searchData, 3000);
      }
    },

    validationForm() {
      if (!this.latest_month) {
        this.latest_monthError = "Lastest month is required !";
      } else {
        this.latest_monthError = "";
      }
      if (!this.no_customer) {
        this.no_customerError = "No customer is required !";
      } else {
        this.no_customerError = "";
      }
    },

    resetForm() {
      console.log("rseset");
      this.latest_month = "1";
      this.no_customer = "";
      this.latest_monthError = "";
      this.no_customerError = "";
      this.loading_search = false;
      this.search_result = [];
      this.search_action = false;
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

tr:nth-child(even) {
  background-color: #afaeae;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #d1d1d3 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.8px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
