<template>
  <div id="app">

    <router-view />
    <div class="loader-wrapper" v-if='showLoader'>
      <div class="theme-loader">
        <div class="loader-p"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLoader: false
    }
  },
  watch: {
    $route() {
      this.showLoader = false;
      setTimeout(() => {
        this.showLoader = false
      }, 500);
    }
  }
}
</script>
<style lang="scss">
.form-control{
  border: #A8A7A7 1.4px solid;
  background-color: aqua;
}

.form-control:focus{
  border: #A8A7A7 1.7px solid;
}



</style>
