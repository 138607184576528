<template>
  <div class="container-fluid dashboard-default-sec">
    <div class="row">
      <div class="col-sm-4">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="users"
                  ><vue-feather type="users"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total User</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_user"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="users"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="grid"
                  ><vue-feather type="grid"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total Role</b>
                <h4 class="mb-0 counter">
                  <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_role"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  />
                </h4>
                <vue-feather class="icon-bg" type="grid"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="card o-hidden border-0">
          <div class="bg-primary b-r-4 card-body">
            <div class="media static-top-widget">
              <div class="align-self-center text-center">
                <i data-feather="file-text"
                  ><vue-feather type="file-text"></vue-feather
                ></i>
              </div>
              <div class="media-body">
                <b class="m-0">Total Customer</b>
                <h4 class="mb-0 counter">
                  <!-- <number
                    class="bold"
                    ref="number1"
                    :from="0"
                    :to="total_customer"
                    :duration="3"
                    :delay="0"
                    easing="Power1.easeOut"
                  /> -->
                  -
                </h4>
                <vue-feather class="icon-bg" type="file-text"></vue-feather>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";
import ApiService from "@/_services/api.service";
import { watch } from "@vue/runtime-core";
import { ref } from "vue";

export default {
  components: {},

  data() {
    return {
      total_user: "0",
      total_role: "3",
      total_customer: "-",
    };
  },
  methods: {
    getTotalData() {
      this.loading = true;
      ApiService.listUser()
        .then((response) => {
          let ttl = response.data.data;
          this.total_user = ttl.length;
          this.loading = false;
        })
        .catch((err) => {
          // console.log("err", err.response.status);
          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getTotalData();
  },
};
</script>
