<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Beranda</a></li>
            <li class="breadcrumb-item active" aria-current="page">Generate</li>
          </ol>
        </nav>
      </div>
      <div class="col-6 text-end"></div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <form class="row g-3 needs-validation" novalidate="">
              <div class="col-12">
                <b>Generate</b>
              </div>
              <div class="col-5 position-relative">
                <label class="form-label" for="validationTooltip02"
                  >Last Month</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="month"
                  v-model="last_month"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? last_monthError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">Agust</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>

                <small
                  class="align-end text-danger"
                  v-if="last_monthError && formSubmitted"
                >
                  {{ last_monthError }}
                </small>
              </div>
              <div class="col-5 position-relative">
                <label class="form-label" for="validationTooltip03"
                  >Select Periode</label
                >
                <select
                  type="text"
                  class="form-control"
                  id="month"
                  v-model="period"
                  @change="validationForm"
                  v-bind:class="
                    formSubmitted
                      ? periodError
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  "
                >
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                </select>

                <small
                  class="align-end text-danger"
                  v-if="periodError && formSubmitted"
                >
                  {{ periodError }}
                </small>
              </div>
              <div class="col-2 position-relative">
                <label class="form-label" for="validationTooltip01"
                  >&nbsp;</label
                >
                <br />
                <b-button
                  variant="primary"
                  class="btn btn-add btn-sm mb-2 px-4 btn-block w-100"
                  @click="generateData()"
                  :disabled="loading_generate"
                >
                  <div v-if="loading_generate">
                    <div class="spinner-border spinner-border-sm" role="status">
                      <span class="sr-only"></span>
                    </div>
                    <span> Loading..</span>
                  </div>
                  <div v-else>Generate Data</div>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="search && !loading_generate">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-end">
                <b-button
                  variant="danger"
                  class="btn btn-add btn-sm px-3"
                  @click="resetForm()"
                >
                  Reset
                </b-button>
              </div>
            </div>
            <div
              class="col-12 text-center my-5 py-5"
              v-if="search_result && !loading_generate"
            >
              <b class="text-success"> Data Success Generate !!</b>
            </div>
            <div
              class="col-12 text-center my-5 py-5"
              v-if="!search_result && !loading_generate"
            >
              <b class="text-danger"> Data Generate Failed !!</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapState } from "vuex";
import ApiService from "@/_services/api.service";
import { Header, Item } from "vue3-easy-data-table";
import Swal from "sweetalert2";

// import Vue3EasyDataTable from "vue3-easy-data-table";

export default {
  // components: { Vue3EasyDataTable },
  data() {
    return {
      url_img: process.env.VUE_APP_API_URL_IMAGE,
      last_month: "1",
      period: "3",
      search: false,
      search_result: false,
      formSubmitted: false,
      loading_generate: false,
      loading: false,
      errorr_status: false,
      tableData: [],
      submitted: false,
    };
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },

  methods: {
    dataGenerate() {
      const data = {
        period: this.period,
        last_month: this.last_month,
      };
      console.log("data", data);
      this.search = true;
      ApiService.generate(data)
        .then((response) => {
          // this.tableData = [];
          console.log("data berhasil");

          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileLink = document.createElement("a");
          const filename = "list_generate.xlsx";

          fileLink.href = fileURL;
          fileLink.setAttribute("download", filename);
          document.body.appendChild(fileLink);
          fileLink.click();

          this.loading_generate = false;
          this.search_result = true;
        })
        .catch((err) => {
          console.log("data error");
          // console.log('err', err);

          this.loading_generate = false;
          if (err.response) {
            if (err.response.status == 401) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
            }
          }
          this.search_result = false;
          this.errorr_status = true;
        });
    },

    generateData() {
      this.formSubmitted = true;
      this.validationForm();
      if (this.last_month && this.period) {
        this.loading_generate = true;
        setTimeout(this.dataGenerate, 3000);
      }
    },

    validationForm() {
      if (!this.last_month) {
        this.last_monthError = "Month is required !";
      } else {
        this.last_monthError = "";
      }
      if (!this.period) {
        this.periodError = "Period is required !";
      } else {
        this.periodError = "";
      }
    },

    resetForm() {
      console.log("rseset");
      this.loading_generate = false;
      this.last_month = "";
      this.period = "";
      this.search = false;
      this.search_result = false;
      this.errorr_status = false;
      this.formSubmitted = false;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    // this.listUserAdmin();
  },
};
</script>

<style lang="scss">
.customize-table {
  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 40px;
  --easy-table-body-row-height: 40px;
  --easy-table-body-row-font-size: 14px;
}

.searchBar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#searchQueryInput {
  width: 100%;
  height: 2.4rem;
  background: #f5f5f5;
  outline: none;
  border: none;
  border-radius: 1.625rem;
  padding: 0 3.5rem 0 1.5rem;
  font-size: 1rem;
}

#searchQuerySubmit {
  width: 3.5rem;
  height: 2.8rem;
  margin-left: -3.5rem;
  background: none;
  border: none;
  outline: none;
}

#searchQuerySubmit:hover {
  cursor: pointer;
}

.font-large-2 {
  font-size: 3rem !important;
}

.form-control {
  border: #d1d1d3 1.4px solid;
}

.form-control:focus {
  border: #a8a7a7 1.8px solid;
}

.count {
  background: rgb(149, 2, 13);
  background: radial-gradient(
    circle,
    rgba(149, 2, 13, 1) 0%,
    rgba(216, 49, 61, 1) 100%
  );
}
</style>
